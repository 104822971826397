import { CreateGreasedLine, Color3, GreasedLineBaseMesh, IDisposable, Scene, AbstractMesh, Vector3, PhysicsRaycastResult, Constants, GreasedLineMeshWidthDistribution } from '@babylonjs/core';

import { assertNonNull } from '../utils';

import { MainCameraType } from './main-camera';

/** Golf ball arrow. */
export class GolfBallArrow implements IDisposable {

	private line: GreasedLineBaseMesh = this.createArrowFor();

	public constructor(
		private readonly camera: MainCameraType,
		private readonly scene: Scene,
		private readonly golfBall: AbstractMesh,
		private readonly minArrowDiameter: number,
	) {}

	/** @inheritdoc */
	public dispose(): void {
		this.line?.material?.dispose();
		this.line?.dispose();
	}

	/** Hide arrow. */
	public hideArrow(): void {
		this.line.isVisible = false;
		this.camera.attachControl();
	}

	/**
	 * Update arrow for provided impulse vector.
	 * @param impulse Impulse vector for which create an arrow.
	 */
	public updateArrowFor(impulse: Vector3): void {
		const points = this.getPointsForTrajectory(impulse);

		let widths: number[] = [];
		const minPointsForArrow = 10;

		if (points.length > minPointsForArrow) {
			const arrowLength = 3;
			this.addPointForArrow(points, arrowLength);
			widths = this.getWidthsOfTrajectory(points.slice(0, -arrowLength));
			widths.push(...this.getArrowWidths());
		} else {
			widths = this.getWidthsOfTrajectory(points);
		}

		assertNonNull(this.line.greasedLineMaterial);
		this.line.greasedLineMaterial.colors = this.getColorsOfTrajectory(points);
		this.line.setPoints(points);
		this.line.widths = widths;

		this.line.isVisible = true;
		this.line.updateLazy();

		this.camera.detachControl();
	}

	private createArrowFor(): GreasedLineBaseMesh {
		return CreateGreasedLine('line', {
			points: [],
			widthDistribution: GreasedLineMeshWidthDistribution.WIDTH_DISTRIBUTION_START,
			lazy: true,
		}, {
			width: 1,
			createAndAssignMaterial: true,
			useColors: true,
			colorsSampling: Constants.TEXTURE_LINEAR_LINEAR,
		}, this.scene);
	}

	// We use the formula to calculate the trajectory taking into account the resistance with a quadratic approximation.
	// See - https://www.physicsforums.com/threads/body-thrown-at-angle-to-the-horizon-taking-into-account-air-resistance.715389/
	private getPointsForTrajectory(impulse: Vector3): Vector3[] {
		const physicsEngine = this.scene.getPhysicsEngine();
		assertNonNull(physicsEngine);
		const tick = physicsEngine.getTimeStep();

		const ballPosition = this.golfBall.absolutePosition.clone();
		const points: Vector3[] = [ballPosition];
		const { gravity } = this.scene;

		const linearDamping = this.golfBall.physicsBody?.getLinearDamping();
		assertNonNull(linearDamping);

		// See - https://en.wikipedia.org/wiki/Drag_coefficient
		const dragCoefficient = 1.17;

		const resistance = linearDamping * dragCoefficient / 2;

		const raycastResult = new PhysicsRaycastResult();
		const physicsPlugin = this.scene.getPhysicsEngine()?.getPhysicsPlugin();
		assertNonNull(physicsPlugin);

		const maxPointsForTrajectory = 125;
		for (let i = 0; i < maxPointsForTrajectory; i++) {
			const time = tick * i;
			const positionWithoutGravity = ballPosition.add(impulse.scale(time));

			const quadraticApproximationCoefficient = new Vector3(
				resistance * impulse.x,
				(-gravity.y + resistance * impulse.y),
				resistance * impulse.z,
			);

			const quadraticApproximation = quadraticApproximationCoefficient.scale(Math.pow(time, 2) / 2).negate();

			const point = positionWithoutGravity.add(quadraticApproximation);

			/** Arrow diameter multiplier for collision tracking with field. */
			const arrowDiameterMultiplierForCollision = 5;
			const maxArrowDiameter = this.minArrowDiameter * arrowDiameterMultiplierForCollision;
			const end = point.add(new Vector3(maxArrowDiameter, maxArrowDiameter, maxArrowDiameter));
			physicsPlugin.raycast(point, end, raycastResult);

			if (raycastResult.hasHit) {
				break;
			}

			points.push(point);
		}

		return points;
	}

	private getColorsOfTrajectory(points: Vector3[]): Color3[] {
		const maxColorValue = 1;
		const colorDifferenceValues = 0.5;
		const colors: Color3[] = [];

		const max = this.getMaxYFromVectors(points);
		const min = this.getMinYFromVectors(points);

		points.forEach(point => {
			const normalizedY = this.getNormalizedY(point.y, min, max);
			const colorValue = maxColorValue - colorDifferenceValues + (normalizedY * colorDifferenceValues);
			colors.push(new Color3(colorValue, colorValue, colorValue));
		});

		return colors;
	}

	private getWidthsOfTrajectory(points: Vector3[]): number[] {
		const widths: number[] = [];

		const max = this.getMaxYFromVectors(points);
		const min = this.getMinYFromVectors(points);
		const arrowDiameterMultiplier = 2;

		points.forEach(point => {
			const normalizedY = this.getNormalizedY(point.y, min, max);
			const widthValue = this.minArrowDiameter * arrowDiameterMultiplier - (normalizedY * this.minArrowDiameter);
			widths.push(widthValue, widthValue);
		});

		return widths;
	}

	private addPointForArrow(points: Vector3[], arrowLength: number): void {
		const indexOfArrowStart = points.length - arrowLength;
		const pointsOfArrowStart = points[indexOfArrowStart];
		points.splice(indexOfArrowStart, 0, pointsOfArrowStart);
	}

	private getArrowWidths(): number[] {
		const coefficientOfMaxArrowWidth = 4;
		const coefficientOfAverageArrowWidth = 2;
		const maxArrowWidth = this.minArrowDiameter * coefficientOfMaxArrowWidth;
		const mediumArrowWidth = this.minArrowDiameter * coefficientOfAverageArrowWidth;
		const arrowWidths = [maxArrowWidth, maxArrowWidth, mediumArrowWidth, mediumArrowWidth, 0, 0];

		return arrowWidths;
	}

	private getNormalizedY(y: number, min: number, max: number): number {
		const normalizedY = (y - min) / (max - min);
		return normalizedY;
	}

	private getMaxYFromVectors(points: Vector3[]): number {
		return points.reduce((previousValue, currentValue) => previousValue.y > currentValue.y ? previousValue : currentValue).y;
	}

	private getMinYFromVectors(points: Vector3[]): number {
		return points.reduce((previousValue, currentValue) => previousValue.y < currentValue.y ? previousValue : currentValue).y;
	}
}
