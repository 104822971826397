import { Injectable } from '@angular/core';
import { assertNonNull, filterNull } from '@golf-ar/core';
import { BaseScene } from '@golf-ar/golf';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';

/** Scene service. */
@Injectable({
	providedIn: 'root',
})
export class SceneService {
	private readonly scene$ = new BehaviorSubject<BaseScene | null>(null);

	/** Is scene initialized. */
	public readonly isSceneInitialized$ = this.scene$.pipe(map(scene => scene != null));

	/** Whether scene control can be enabled or not. */
	public readonly canToggleSceneControl$ = this.scene$.pipe(
		filterNull(),
		switchMap(scene => scene.gameManager.canToggleSceneControl$),
	);

	/** Whether scene control is active or not. */
	public readonly isSceneControlActive$ = this.scene$.pipe(
		filterNull(),
		switchMap(scene => scene.gameManager.isSceneControlActive$),
	);

	/** End game strokes. */
	public readonly endGameStrokes$ = this.scene$.pipe(
		filterNull(),
		switchMap(scene => scene.gameManager.endGameStrokes$),
	);

	/** Whether scene is loading or not. */
	public readonly isLoading$ = this.scene$.pipe(
		filterNull(),
		switchMap(scene => scene.gameManager.isLoading$),
	);

	/** Scene instance. */
	public get sceneInstance(): BaseScene {
		const scene = this.scene$.value;
		assertNonNull(scene);
		return scene;
	}

	/**
	 * Initialize scene.
	 * @param element The canvas element.
	 */
	public initialize(element: HTMLCanvasElement): void {
		const scene = new BaseScene(element);
		this.scene$.next(scene);
	}

	/** Resets game. */
	public resetGame(): Observable<BaseScene> {
		return this.scene$.pipe(
			filterNull(),
			tap(scene => {
				scene.gameManager.resetGame();
				assertNonNull(scene.sceneControlManager);
				scene.sceneControlManager.toggleSceneControl();
			}),
		);
	}

	/** Toggles scene control. */
	public toggleSceneControl(): Observable<BaseScene> {
		return this.scene$.pipe(
			filterNull(),
			tap(scene => {

				assertNonNull(scene.sceneControlManager);
				scene.sceneControlManager.toggleSceneControl();
			}),
		);
	}

	/** Disposes scene. */
	public disposeScene(): void {
		if (this.scene$.value != null) {
			this.scene$.value.dispose();
			this.scene$.next(null);
		}
	}
}
