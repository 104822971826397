import { enumToArray } from '../utils';

/** Names of meshes. */
export enum FieldMeshName {
	SandBunker = 'SandBunker',
	Field = 'Field',
	Green = 'Green',
	Tee = 'Tee',
	Water = 'Water',
	Fireway = 'Fireway',
	Hole = 'Hole',
	Rock = 'Rock',
	Spruce = 'Spruce',
	Tree = 'Tree',
	Mushroom = 'Mushroom',
	Bush = 'Bush',
	Stone = 'Stone',
	Flower = 'Flower',
	Grass = 'Grass',
	Branch = 'Branch',
	FlagLogo = 'Flag_Logo',
	Flagpole = 'Flagpole',
}

export namespace FieldMeshName {

	/** Names of meshes that make up the surface of the field, for example water. */
	const FIELD_SURFACE_MESHES: readonly FieldMeshName[] = [
		FieldMeshName.SandBunker,
		FieldMeshName.Field,
		FieldMeshName.Green,
		FieldMeshName.Tee,
		FieldMeshName.Water,
		FieldMeshName.Fireway,
		FieldMeshName.Hole,
	];

	/** Names of meshes with a physics body. */
	const MESHES_WITH_PHYSICS_BODY: readonly FieldMeshName[] = [
		FieldMeshName.SandBunker,
		FieldMeshName.Field,
		FieldMeshName.Green,
		FieldMeshName.Tee,
		FieldMeshName.Fireway,
		FieldMeshName.Hole,
		FieldMeshName.Rock,
		FieldMeshName.Spruce,
		FieldMeshName.Tree,
	];

	/**
	 * Type guard for FieldMeshName.
	 * @param value Value.
	 */
	export function isValid(value: unknown): value is FieldMeshName {
		const meshNameList = FieldMeshName.toArray();
		return meshNameList.includes(value as FieldMeshName);
	}

	/** Get an array of field mesh names. */
	export function toArray(): FieldMeshName[] {
		return enumToArray(FieldMeshName);
	}

	/**
	 * Checks whether the value belongs to field surface mesh.
	 * @param value Value.
	 */
	export function isFieldSurfaceMesh(value: FieldMeshName): boolean {
		return FIELD_SURFACE_MESHES.includes(value);
	}

	/**
	 * Checks whether the value belongs to meshes with physics body.
	 * @param value Value.
	 */
	export function isMeshesWithPhysicsBody(value: FieldMeshName): boolean {
		return MESHES_WITH_PHYSICS_BODY.includes(value);
	}
}
