import { IDisposable } from '@babylonjs/core';
import { BehaviorSubject } from 'rxjs';

/** Scene control manager. */
export class SceneControlManager implements IDisposable {
	private isEnabled = true;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly isEnabledFieldDragManager: (value: boolean) => void,
		private readonly isEnabledBallTrajectoryManager: (value: boolean) => void,
		private readonly isEnabledFieldControlManager: (value: boolean) => void,
		private readonly isSceneControlActive: BehaviorSubject<boolean>,
	) {
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	/** Toggles scene control. */
	public toggleSceneControl(): void {
		this.isEnabled = !this.isEnabled;
		this.isEnabledFieldDragManager(this.isEnabled);
		this.isEnabledFieldControlManager(this.isEnabled);
		this.isEnabledBallTrajectoryManager(!this.isEnabled);
		this.isSceneControlActive.next(this.isEnabled);
	}
}
