<div class="dialog">
	<ng-container mat-dialog-content>
		<div class="dialog__content">
			<gaw-game-result-dialog-content
				[type]="gameResultType"
				[strokesCount]="dialogData.strokesCount"
			/>
		</div>
	</ng-container>
	<ng-container mat-dialog-actions>
		<div class="dialog__actions">
			@if (gameResultType === "miss") {
				<!-- TODO (Sherstkov) Delete 'hide' class when implementation is ready -->
				<button
					(click)="onOpenTutorial()"
					type="button"
					class="secondary visually-hidden"
				>
					How to Play?
				</button>
				<button
					(click)="onStart()"
					type="button"
					class="primary"
				>
					<mat-icon
						color="primary"
						svgIcon="ar"
					/>
					Try again
				</button>
			} @else {
				<button
					(click)="onStart()"
					type="button"
					class="primary"
				>
					<mat-icon
						color="primary"
						svgIcon="ar"
					/>
					Start game
				</button>
				<!-- TODO (Sherstkov) Delete 'hide' class when implementation is ready -->
				<button
					(click)="onShare()"
					type="button"
					class="secondary visually-hidden"
				>
					Share
				</button>
			}
		</div>
	</ng-container>
</div>
