import { AbstractMesh } from '@babylonjs/core';

import { assertNonNull } from './assert-non-null';

const ROOT_MESH_NAME = '__root__';

/**
 * Check the location of the root mesh.
 * @param meshes Meshes.
 */
export function checkRootMeshLocation(meshes: AbstractMesh[]): void {
	const mesh = meshes.at(0);
	assertNonNull(mesh);
	if (mesh.name !== ROOT_MESH_NAME) {
		throw new Error('__root__ is not in the first element');
	}
}

/**
 * Get the root mesh.
 * @param meshes Meshes.
 */
export function getRootMesh(meshes: AbstractMesh[]): AbstractMesh {
	const rootMesh = meshes.find(mesh => mesh.name === ROOT_MESH_NAME);
	assertNonNull(rootMesh);

	return rootMesh;
}

/**
 * Get the meshes without root.
 * @param meshes Meshes.
 */
export function getMeshesWithoutRoot(meshes: AbstractMesh[]): AbstractMesh[] {
	return meshes.filter(mesh => mesh.name !== ROOT_MESH_NAME);
}
