import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { IconsService } from './service/icons.service';
import { ICONS } from './utils/icons-declaration';
import { GameComponent } from './features/game/game.component';

/** App component. */
@Component({
	selector: 'gaw-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [GameComponent],
})
export class AppComponent {
	private readonly iconsService = inject(IconsService);

	public constructor() {
		ICONS.forEach(icon => this.iconsService.addIcon(icon, 'assets/icons/'));
	}
}
