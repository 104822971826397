import { Scene, ArcRotateCamera, Vector3, Viewport, IDisposable, Mesh, TransformNode, Scalar, Observer } from '@babylonjs/core';
import { assertNonNull, LayerMask } from '@golf-ar/core';

const CAMERA_UPPER_BETA_LIMIT = Math.PI / 3;
const CAMERA_LOWER_RADIUS_LIMIT = 2;
const CAMERA_RADIUS_LIMIT = 4;

const LERP_SPEED = 0.01;

/** Camera that follows the ball during shot. */
export class BallFollowCamera implements IDisposable {
	private readonly instance: ArcRotateCamera;

	private readonly resources: IDisposable[] = [];

	private updateCameraObservable: Observer<Scene> | null = null;

	public constructor(
		private readonly scene: Scene,
		private readonly target: Vector3,
		private readonly ball: Mesh,
		private readonly viewport: Viewport,
	) {
		this.instance = this.createCamera();
		this.setupCameraBehavior();
	}

	/** Hide camera. */
	public hideCamera(): void {
		this.scene.removeCamera(this.instance);
	}

	/** Display camera. */
	public displayCamera(): void {
		assertNonNull(this.scene.activeCameras);
		this.scene.activeCameras.push(this.instance);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.scene.onBeforeRenderObservable.remove(this.updateCameraObservable);
		this.resources.forEach(resource => resource.dispose());
	}

	private createCamera(): ArcRotateCamera {
		const camera = new ArcRotateCamera('ballFollowCamera', -Math.PI / 2, Math.PI / 3, 5, this.target, this.scene);
		camera.viewport = this.viewport;
		camera.inputs.clear();
		this.resources.push(camera);

		return camera;
	}

	private setupCameraBehavior(): void {
		const cameraTarget = new Vector3();
		const followCamera = new TransformNode('followCamera', this.scene);
		const followCameraPivot = new TransformNode('followCameraPivot', this.scene);
		this.instance.layerMask = LayerMask.ViewportAndDefault;
		followCameraPivot.parent = followCamera;
		followCamera.parent = this.ball;

		this.instance.useAutoRotationBehavior = true;
		this.instance.upperBetaLimit = CAMERA_UPPER_BETA_LIMIT;

		this.updateCameraObservable = this.scene.onBeforeRenderObservable.add(() => {
			this.updateCamera(cameraTarget, followCameraPivot);
		});
	}

	private updateCamera(cameraTarget: Vector3, followCameraPivot: TransformNode): void {
		// Updating camera target
		cameraTarget.copyFrom(this.ball.getAbsolutePosition());
		this.instance.setTarget(cameraTarget);

		// Updating camera position
		const followCameraPivotPosition = followCameraPivot.getAbsolutePosition();
		this.instance.position = Vector3.Lerp(this.instance.position, followCameraPivotPosition, LERP_SPEED);

		// Camera radius restriction
		this.instance.lowerRadiusLimit = CAMERA_LOWER_RADIUS_LIMIT;
		if (this.instance.radius < CAMERA_RADIUS_LIMIT) {
			this.instance.radius = Scalar.Lerp(this.instance.radius, CAMERA_RADIUS_LIMIT, LERP_SPEED);
		}
	}
}
