import { ReadonlyRecord } from '../utils';

/** Possible variants of how well the user played or the user lost. */
export type GameResultType = 'great' | 'nice' | 'par' | 'miss';

const STROKE_LIMITS_BY_RESULT: ReadonlyRecord<GameResultType, number> = {
	great: 3,
	nice: 4,
	par: 5,
	miss: 9,
};

/**
 * Gets game result type based on strokes count.
 * @param strokesCount Number of strokes.
 */
export function getGameResultType(strokesCount: number): GameResultType {
	if (strokesCount <= STROKE_LIMITS_BY_RESULT.great) {
		return 'great';
	}
	if (strokesCount === STROKE_LIMITS_BY_RESULT.nice) {
		return 'nice';
	}
	if (strokesCount >= STROKE_LIMITS_BY_RESULT.par && strokesCount < STROKE_LIMITS_BY_RESULT.miss) {
		return 'par';
	}
	return 'miss';
}
