import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, AfterViewInit, inject, DestroyRef } from '@angular/core';
import { assertNonNull, DeviceOrientationManager } from '@golf-ar/core';
import { filter, tap } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SceneService } from '../../../service/scene.service';

/** Orientation overlay message component. */
@Component({
	selector: 'gaw-orientation-overlay-message',
	templateUrl: './orientation-overlay-message.component.html',
	styleUrls: ['./orientation-overlay-message.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrientationOverlayMessageComponent implements AfterViewInit {

	/** Orientation overlay element. */
	@ViewChild('orientationOverlay')
	public orientationOverlay: ElementRef<HTMLDivElement> | null = null;

	private readonly sceneService = inject(SceneService);

	private readonly destroyRef = inject(DestroyRef);

	/** @inheritdoc */
	public ngAfterViewInit(): void {
		this.sceneService.isSceneInitialized$
			.pipe(
				filter(isSceneInitialized => isSceneInitialized === true),
				tap(() => {
					assertNonNull(this.orientationOverlay);
					DeviceOrientationManager.registerDeviceOrientationChange(this.orientationOverlay.nativeElement);
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();

	}
}
