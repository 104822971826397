import { BehaviorSubject } from 'rxjs';
import { AbstractGolfBallTrajectoryManager, GolfClubImpactSetting, MainCameraType, assertNonNull } from '@golf-ar/core';
import { AnimationGroup, Scene, Vector3 } from '@babylonjs/core';

import { GOLF_BALL_RADIUS, GolfBall } from './golf-ball';
import { BallTrail } from './ball-trail';
import { Golfer } from './golfer';
import { BallFollowCamera } from './ball-follow-camera';

const MIN_VALUE_Y = -0.5;

/** Golf ball trajectory manager. */
export class GolfBallTrajectoryManager extends AbstractGolfBallTrajectoryManager {
	private hasStroke = false;

	private lastLinearVelocity = Vector3.Zero();

	private lastAngularVelocity = Vector3.Zero();

	// TODO (Sherstkov): Refactor complex constructor.
	public constructor(
		protected override readonly scene: Scene,
		protected override readonly camera: MainCameraType,
		private readonly ballFollowCamera: BallFollowCamera,
		private readonly golfBall: GolfBall,
		private readonly ballTrail: BallTrail,
		private readonly golfer: Golfer,
		private readonly winAnimation: AnimationGroup,
		private readonly addStroke: () => void,
		private readonly getIsBallInHole: () => boolean,

		/** The ball doesn't move and the ball trajectory isn't selected. */
		private readonly canToggleSceneControl$: BehaviorSubject<boolean>,
	) {
		super(scene, golfBall.instanceMesh, camera, GOLF_BALL_RADIUS);

		this.golfer.changeGolferPosition();

		// Observable for disabling actions when ball is moving.
		this.scene.onAfterPhysicsObservable.add(() => {

			// Check for action manager is needed in order to avoid multiple register and unregister it.
			assertNonNull(this.ballMesh.actionManager);
			const { hasPickTriggers } = this.ballMesh.actionManager;
			const isStopped = this.isStopped();

			const isReadyToNextStroke = isStopped &&
				!hasPickTriggers &&
				this.isEnabled &&
				!this.golfer.instanceAnimation.isPlaying &&
				!this.winAnimation.isPlaying &&
				this.ballMesh.absolutePosition.y >= MIN_VALUE_Y;

			if (isReadyToNextStroke) {

				// It's necessary that the ball finally stops
				this.golfBall.instancePhysicsBody.setLinearVelocity(Vector3.Zero());
				this.golfBall.instancePhysicsBody.setAngularVelocity(Vector3.Zero());

				if (!this.getIsBallInHole()) {
					this.golfer.changeGolferPosition();
					this.golfer.instanceAnimation.reset();
				}

				this.ballMesh.actionManager.registerAction(this.action);
				this.isBallStopped = true;
				ballFollowCamera.hideCamera();
				this.ballTrail.isVisible = false;

				if (this.hasStroke) {
					this.addStroke();
					this.hasStroke = false;
					this.canToggleSceneControl$.next(true);
				}
			}

			if (!isStopped && hasPickTriggers && this.golfer.instanceAnimation.isPlaying) {
				this.isBallStopped = false;
			}
		});
	}

	/** @inheritdoc */
	protected override getImpactSetting(): GolfClubImpactSetting {
		return this.golfer.getGolfClubImpactSetting();
	}

	/** @inheritdoc */
	protected override createBallTrajectory(): void {
		if (this.pickedPoint == null) {
			return;
		}
		this.canToggleSceneControl$.next(false);
		this.impulse = this.calculateImpulse();
		this.golfBallArrow.updateArrowFor(this.impulse);
		this.golfer.rotateTowards(this.impulse);
	}

	/** @inheritdoc */
	protected override onMouseUp(): void {
		if (this.pickedPoint == null) {
			return;
		}
		assertNonNull(this.ballMesh.actionManager);
		this.ballMesh.actionManager.unregisterAction(this.action);

		this.golfer.instanceAnimation.play(false);
		this.hideLines();
		this.impactAngleIndicator.hideIndicator();
		this.displayActionCamera();
	}

	/** Starts impulse generation. */
	public startGenerateImpulse(): void {
		this.golfBall.ballPositionBeforeImpact = this.ballMesh.absolutePosition;
		this.ballTrail.isVisible = true;
		this.ballFollowCamera.shouldSeeGolfer = false;
		this.scene.onAfterRenderObservable.add(() => {
			if (this.ballMesh.absolutePosition.y < MIN_VALUE_Y) {
				this.golfBall.restorePositionAfterFall();
			}
		});

		this.scene.onAfterRenderObservable.addOnce(() => {
			this.generateImpulse();
		});

		this.hasStroke = true;
	}

	private displayActionCamera(): void {
		this.ballFollowCamera.shouldSeeGolfer = true;
		this.ballFollowCamera.displayCamera();
	}

	private isStopped(): boolean {
		const linearVelocity = this.golfBall.instancePhysicsBody.getLinearVelocity();
		const angularVelocity = this.golfBall.instancePhysicsBody.getAngularVelocity();

		const hasLinearVelocity = this.hasVelocity(linearVelocity);
		const hasAngularVelocity = this.hasVelocity(angularVelocity);

		const hadVelocityChanged = this.lastLinearVelocity.equals(linearVelocity) && this.lastAngularVelocity.equals(angularVelocity);

		this.lastLinearVelocity = linearVelocity;
		this.lastAngularVelocity = angularVelocity;

		return !(hasLinearVelocity || hasAngularVelocity) || hadVelocityChanged;
	}

	private hasVelocity(velocity: Vector3): boolean {
		return this.getAbsoluteVelocityValueFor(velocity.x) > 0 ||
			this.getAbsoluteVelocityValueFor(velocity.y) > 0 ||
			this.getAbsoluteVelocityValueFor(velocity.z) > 0;
	}
}
