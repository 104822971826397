import { ChangeDetectionStrategy, Component, ElementRef, inject, NgZone, ViewChild, AfterViewInit, OnDestroy, DestroyRef } from '@angular/core';
import { assertNonNull } from '@golf-ar/core';
import { filter, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SceneService } from '../../../service/scene.service';

/** Scene component. */
@Component({
	selector: 'gaw-scene',
	templateUrl: './scene.component.html',
	styleUrls: ['./scene.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SceneComponent implements AfterViewInit, OnDestroy {
	/** Canvas element. */
	@ViewChild('canvas')
	public canvas: ElementRef<HTMLCanvasElement> | null = null;

	private readonly zone = inject(NgZone);

	private readonly sceneService = inject(SceneService);

	private readonly abortController = new AbortController();

	private readonly destroyRef = inject(DestroyRef);

	/** @inheritdoc */
	public ngAfterViewInit(): void {
		// Show loading screen before the full XR library has been loaded and registers a change in the orientation of the device.
		window.onload = () => {
			XRExtras.Loading.showLoading({ onxrloaded: this.onXrLoaded.bind(this) });

			this.sceneService.isSceneInitialized$.pipe(
				filter(isSceneInitialized => isSceneInitialized === true),
				tap(() => window.addEventListener(
					'resize', this.sceneService.sceneInstance.onResize, { signal: this.abortController.signal },
				)),
				takeUntilDestroyed(this.destroyRef),
			).subscribe();
		};

		// const screenlog = (): void => {
		// // @ts-ignore
		// 	window.XRExtras.DebugWebViews.enableLogToScreen();
		// };

		// @ts-ignore
		// eslint-disable-next-line no-unused-expressions
		// window.XRExtras ? screenlog() : window.addEventListener('xrextrasloaded', screenlog.bind(this));
		// this.zone.runOutsideAngular(() => {
		// 	this.scene = new BaseScene(this.canvas.nativeElement);
		// });
	}

	/** @inheritdoc */
	public ngOnDestroy(): void {
		this.sceneService.disposeScene();
		this.abortController.abort();
	}

	private onXrLoaded(): void {
		XR8.addCameraPipelineModules([
			XRExtras.Loading.pipelineModule(),
			XRExtras.RuntimeError.pipelineModule(),
			XR8.XrController.pipelineModule(),
			XR8.CameraPixelArray.pipelineModule(),
		]);

		this.zone.runOutsideAngular(() => {
			assertNonNull(this.canvas);
			this.sceneService.initialize(this.canvas.nativeElement);
		});
	}
}
