import { NonFunctionalAndArray } from './types';

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T extends Record<string, unknown>>(enumeration: T): NonFunctionalAndArray<T[keyof T]>[] {
	return Object.keys(enumeration)
		.filter(key => isNaN(Number(key)))
		.map(key => enumeration[key])
		.filter(
			(val): val is NonFunctionalAndArray<T[keyof T]> =>
				typeof val === 'number' || typeof val === 'string',
		);
}
