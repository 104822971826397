import { AbstractMesh, Color3, IDisposable, PBRMaterial, PhysicsAggregate, PhysicsShapeType, Scene, SceneLoader, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import { FieldMeshName, assertNonNull, checkRootMeshLocation, getMeshesWithoutRoot, getValidName } from '@golf-ar/core';

import { VISIBILITY_OF_MESH } from './scene';

/** Golf field. */
export class Field implements IDisposable {

	/** Instance of the field. */
	private instance: AbstractMesh | null = null;

	private physicsAggregate: PhysicsAggregate[] = [];

	private meshes: AbstractMesh[] | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
	) { }

	/** Instance mesh. */
	public get instanceMesh(): AbstractMesh {
		assertNonNull(this.instance);
		return this.instance;
	}

	/** Instance meshes. */
	public get instanceMeshes(): AbstractMesh[] {
		assertNonNull(this.meshes);
		return this.meshes;
	}

	/** Physics aggregates. */
	public get physicsAggregatesInstance(): PhysicsAggregate[] {
		return this.physicsAggregate;
	}

	/** Start position. */
	public get startPosition(): Vector3 {
		const startGround = this.getFieldMeshByName(FieldMeshName.Tee);

		const { min, max } = startGround.getHierarchyBoundingVectors();
		const positionX = (max.x + min.x) / 2;
		const positionY = max.y;
		const positionZ = (max.z + min.z) / 2;
		const position = new Vector3(positionX, positionY, positionZ);

		return position;
	}

	/** Hole position. */
	public get holePosition(): Vector3 {
		const hole = this.getFieldMeshByName(FieldMeshName.Hole);

		return hole.absolutePosition;
	}

	/** Enables and disables pre-step, that consists in updating physics body. */
	public set shouldDisablePhysicsBodyStep(isDisabled: boolean) {
		/** Before disabling pre-step, we need to wait for all values to change, for example, position, rotation, etc. */
		if (isDisabled) {
			this.scene.onAfterPhysicsObservable.addOnce(() => {
				this.physicsAggregatesInstance.forEach(physicsAggregate => {
					assertNonNull(physicsAggregate.body);
					physicsAggregate.body.disablePreStep = isDisabled;
				});
			});
			return;
		}
		this.physicsAggregatesInstance.forEach(physicsAggregate => {
			assertNonNull(physicsAggregate.body);
			physicsAggregate.body.disablePreStep = isDisabled;
		});
	}

	/**
	 * Changes the visibility of meshes.
	 * @param visibility Visibility.
	 */
	public setMeshVisibility(visibility: number): void {
		const meshesWithoutRoot = getMeshesWithoutRoot(this.instanceMeshes);
		meshesWithoutRoot.forEach(mesh => {
			const name = getValidName(mesh, FieldMeshName.isValid);
			const isVisible = FieldMeshName.isFieldSurfaceMesh(name);

			if (isVisible) {
				mesh.visibility = visibility;
			}
		});
	}

	/** Creates field. */
	public async create(): Promise<void> {
		this.meshes = await this.createMeshes();

		const currentMesh = this.meshes.at(0);
		assertNonNull(currentMesh);
		this.instance = currentMesh;

		this.setMeshVisibility(VISIBILITY_OF_MESH);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private async createMeshes(): Promise<AbstractMesh[]> {
		const { meshes } = await SceneLoader.ImportMeshAsync('', './assets/models/fields/', 'big-field.glb', this.scene);

		const fieldMesh = meshes.find(mesh => mesh.material?.name === 'Field');
		const treesMesh = meshes.find(mesh => mesh.material?.name === 'Trees');
		const logoSaritasaMesh = meshes.find(mesh => mesh.material?.name === 'LogoSaritasa');

		this.setMaterialOnMesh(fieldMesh);
		this.setMaterialOnMesh(treesMesh);
		this.setMaterialOnMesh(logoSaritasaMesh);

		checkRootMeshLocation(meshes);
		const fieldMeshes = meshes.slice(1);

		fieldMeshes.forEach(mesh => {
			this.setPhysicsAggregateOnMesh(mesh);
			mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
			mesh.isPickable = false;
		});

		this.resources.push(...meshes);

		return meshes;
	}

	private setMaterialOnMesh(mesh?: AbstractMesh): void {
		assertNonNull(mesh);

		// In runtime, the object type is PBRMaterial
		const material = mesh.material as PBRMaterial;
		material.emissiveColor = Color3.White();
		material.emissiveTexture = material.albedoTexture;
		material.disableLighting = true;

		this.resources.push(material);
	}

	private getFieldMeshByName(name: FieldMeshName): AbstractMesh {
		const fieldMesh = this.instanceMeshes.find(mesh => mesh.name === name);
		assertNonNull(fieldMesh);

		return fieldMesh;
	}

	private setPhysicsAggregateOnMesh(mesh: AbstractMesh): void {
		const name = getValidName(mesh, FieldMeshName.isValid);
		const shouldHasPhysicsBody = FieldMeshName.isMeshesWithPhysicsBody(name);

		if (shouldHasPhysicsBody) {
			const isFieldSurface = FieldMeshName.isFieldSurfaceMesh(name);

			const physicsAggregate = new PhysicsAggregate(
				mesh,
				isFieldSurface ? PhysicsShapeType.MESH : PhysicsShapeType.CONVEX_HULL,
				{ mass: 0, restitution: 0.25 },
			);

			this.physicsAggregate.push(physicsAggregate);
			this.resources.push(physicsAggregate);
		}
	}
}
