import { IconDeclaration } from '../service/icons.service';

/** List of icons to register for mat-icon. */
export const ICONS: IconDeclaration[] = [
	{
		title: 'ar',
		url: 'ar.svg',
	},
	{
		title: 'help',
		url: 'help.svg',
	},
	{
		title: 'refresh',
		url: 'refresh.svg',
	},
];
