// 8th wall return the rotation of the UI from portrait, in degrees (-90, 0, 90, 180).
// See - https://www.8thwall.com/docs/api/camerapipelinemodule/ondeviceorientationchange
type DeviceOrientation = 0 | 90 | -90 | 180;

type DeviceOrientationChangeOptions = {

	/** Orientation of the screen in degrees. */
	readonly orientation: DeviceOrientation;
};

/** Device orientation manager. */
export class DeviceOrientationManager {
	/**
	 * Registers a change in the orientation of the device.
	 * @param message Message element.
	 */
	public static registerDeviceOrientationChange(message: HTMLDivElement): void {
		XR8.addCameraPipelineModule({
			name: 'orientation',
			onStart({ orientation }: DeviceOrientationChangeOptions) {
				DeviceOrientationManager.changeOrientationOverlayDisplay(orientation, message);
			},
			onDeviceOrientationChange({ orientation }: DeviceOrientationChangeOptions) {
				DeviceOrientationManager.changeOrientationOverlayDisplay(orientation, message);
			},
		});
	}

	private static changeOrientationOverlayDisplay(orientation: DeviceOrientation, message: HTMLDivElement): void {
		if (orientation === 0) {
			message.style.display = 'none';
		}
		if (orientation === 90 || orientation === -90) {
			message.style.display = 'grid';
		}
	}
}
