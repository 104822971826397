import { ErrorHandler, Injectable } from '@angular/core';

/** Presents an unhandled error message to a user. */
@Injectable({
	providedIn: 'root',
})
export class ErrorNotifier implements ErrorHandler {

	/** @inheritdoc */
	public handleError(error: unknown): void {

		// It is required to debug the 8th wall more efficiently.
		// TODO (Romanov): Remove this code after we finished develop stage.
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
