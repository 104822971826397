import { Scene, FreeCamera, Vector3, IDisposable } from '@babylonjs/core';

/** Main camera type. */
export type MainCameraType = FreeCamera;

const MIN_Y_POSITION = 5;
const MAX_Y_POSITION = 150;

/** App main camera. */
export class MainCamera implements IDisposable {

	/** Camera instance. */
	public readonly instance: MainCameraType;

	public constructor(private readonly scene: Scene) {
		this.instance = this.initializeCamera();

		// Connect the camera to the XR engine and show camera feed
		// @ts-ignore
		this.instance.addBehavior(XR8.Babylonjs.xrCameraBehavior({ webgl2: true }), true);
	}

	/** Change the camera position based on the front position.*/
	public set cameraPositionByFrontPosition(distance: number) {
		const { x, y, z } = this.instance.getFrontPosition(distance);
		const { rotationQuaternion } = this.instance;
		if (y > MIN_Y_POSITION && y < MAX_Y_POSITION) {
			XR8.XrController.updateCameraProjectionMatrix({
				origin: { x, y, z },
				facing: rotationQuaternion,
			});

			XR8.XrController.recenter();
		}
	}

	/** @inheritdoc */
	public dispose(): void {
		this.instance.dispose();
	}

	private initializeCamera(): FreeCamera {
		const camera = new FreeCamera('freeCam', new Vector3(-50, 25, -5.5), this.scene);
		camera.rotation = new Vector3(0.6, 1.3, 0);
		camera.inputs.addMouseWheel();
		camera.attachControl();
		return camera;
	}
}
