import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

import { ErrorNotifier } from '../error-logger';

/** App config. */
export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: ErrorHandler,
			useClass: ErrorNotifier,
		},
		provideLottieOptions({
			player: () => player,
		}),
	],
};
