@let animation = currentAnimation();
@if (isVisible() && animation) {
	<div
		class="overlay tutorial-overlay"
		(click)="switchAnimation()"
	>
		<div class="tutorial-text-container">
			<p class="tutorial-text tutorial-title">{{ animation.title }}</p>
		</div>
		<div class="tutorial-text-container">
			<p class="tutorial-text">{{ animation.message }}</p>
		</div>
		<ng-lottie
			class="tutorial-animation"
			[options]="animation.options"
		></ng-lottie>
	</div>
}
