import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SceneService } from '../../service/scene.service';

import { SceneComponent } from './scene/scene.component';
import { GameResultDialogComponent, GameResultDialogData } from './game-result-dialog/game-result-dialog.component';
import { WelcomingDialogComponent } from './welcoming-dialog/welcoming-dialog.component';
import { OrientationOverlayMessageComponent } from './orientation-overlay-message/orientation-overlay-message.component';

const GAME_RESULT_DIALOG_CONFIG: MatDialogConfig = {
	width: '95vw',
	height: '50vh',
	maxWidth: '450px',
	minHeight: '420px',
	disableClose: true,
};

const WELCOMING_DIALOG_CONFIG: MatDialogConfig = {
	width: '100vw',
	height: '100vh',
	maxWidth: '100vw',
};

/** Game component. */
@Component({
	selector: 'gaw-game',
	templateUrl: './game.component.html',
	styleUrls: ['./game.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SceneComponent, OrientationOverlayMessageComponent],
})
export class GameComponent implements OnInit {
	private readonly dialog = inject(MatDialog);

	private readonly sceneService = inject(SceneService);

	private readonly destroyRef = inject(DestroyRef);

	public constructor() {
		this.openWelcomingDialog();
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		this.sceneService.endGameStrokes$.pipe(
			tap(strokesCount => this.openGameResultDialog(strokesCount)),
			takeUntilDestroyed(this.destroyRef),
		).subscribe();
	}

	private openWelcomingDialog(): void {
		this.dialog.open(WelcomingDialogComponent, WELCOMING_DIALOG_CONFIG);
	}

	private openGameResultDialog(strokesCount: number): void {
		this.dialog.open<GameResultDialogComponent, GameResultDialogData>(GameResultDialogComponent, {
			...GAME_RESULT_DIALOG_CONFIG,
			data: { strokesCount },
		});
	}
}
