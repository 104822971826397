import { IDisposable } from '@babylonjs/core';
import { AdvancedDynamicTexture, Control, Rectangle, TextBlock } from '@babylonjs/gui';

/** Loading manager. */
export class LoadingManager implements IDisposable {
	private readonly resources: IDisposable[] = [];

	/** Create overlay for loading. */
	public createOverlay(): void {
		const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('Loading');
		this.resources.push(advancedTexture);

		const overlay = new Rectangle();
		overlay.thickness = 0;
		overlay.width = '100%';
		overlay.height = '100%';
		overlay.background = 'rgba(0, 0, 0, 0.35)';
		overlay.isPointerBlocker = true;
		advancedTexture.addControl(overlay);
		const messageTextBlock = this.createMessageTextBlock();
		overlay.addControl(messageTextBlock);
	}

	/** Create message text block. */
	private createMessageTextBlock(): TextBlock {
		const textBlock = new TextBlock();
		textBlock.color = 'white';
		textBlock.fontSize = '48px';
		textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
		textBlock.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
		textBlock.text = 'Loading...';
		return textBlock;
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}
}
