import { Color3, IDisposable, Mesh, PolygonMeshBuilder, Scene, StandardMaterial, Vector2, Vector3 } from '@babylonjs/core';
import { assertNonNull } from '@golf-ar/core';
import * as earcut from 'earcut';

import { Field } from './field';

// The distance between the field and the corners.
const FIELD_MARGIN = 1;

/** Minimum and maximum world vectors of field. */
type FieldBoundingVectors = {

	/** Minimum world vectors of field. */
	readonly min: Vector3;

	/** Maximum world vectors of field. */
	readonly max: Vector3;

};

/** Parameters required to create polygon. */
type PolygonParams = {

	/** Mesh for cloning. */
	readonly mesh: Mesh;
} & FieldBoundingVectors;

/** Golf field corners. */
export class GolfFieldCorners implements IDisposable {

	private instance: Mesh | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
	) {}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	/**
	 * Create corners.
	 * @param field Field.
	 */
	public create(field: Field): void {
		this.instance = this.createCorners(field);
	}

	/** Corners. */
	public get instanceMesh(): Mesh {
		assertNonNull(this.instance);
		return this.instance;
	}

	/**
	 * Changes the visibility of corners.
	 * @param visibility Visibility.
	 */
	public setCornersVisibility(visibility: number): void {
		this.instanceMesh.visibility = visibility;
	}

	private createCorners(field: Field): Mesh {
		const { min, max } = field.instanceMesh.getHierarchyBoundingVectors();

		const polygonRightBack = this.createPolygonRightBack({ min, max });
		const material = this.createMaterial();
		polygonRightBack.material = material;

		const polygonLeftBack = this.createPolygonLeftBack({ mesh: polygonRightBack, min, max });
		const polygonLeftFront = this.createPolygonLeftFront({ mesh: polygonLeftBack, min, max });
		const polygonRightFront = this.createPolygonRightFront({ mesh: polygonLeftFront, min, max });

		const cornersMesh = Mesh.MergeMeshes([polygonRightBack, polygonLeftBack, polygonLeftFront, polygonRightFront]);
		assertNonNull(cornersMesh);
		this.resources.push(cornersMesh);

		return cornersMesh;
	}

	private createPolygonRightBack(params: FieldBoundingVectors): Mesh {
		const { min, max } = params;

		// The size of the corner depends on the width of the field.
		const coefficientOfCornerSize = 0.2;
		const cornerThickness = 0.5;

		const size = (Math.abs(max.z) + Math.abs(min.z)) * coefficientOfCornerSize - FIELD_MARGIN;
		const polygonCorners = [
			new Vector2(min.x - FIELD_MARGIN, min.z - FIELD_MARGIN),
			new Vector2(min.x + size, min.z - FIELD_MARGIN),
			new Vector2(min.x + size, min.z - cornerThickness),
			new Vector2(min.x - cornerThickness, min.z - cornerThickness),
			new Vector2(min.x - cornerThickness, min.z + size),
			new Vector2(min.x - FIELD_MARGIN, min.z + size),
		];

		const polygonTriangulation = new PolygonMeshBuilder('polygonTriangulation', polygonCorners, this.scene, earcut);
		const polygon = polygonTriangulation.build();

		return polygon;
	}

	private createPolygonLeftBack(params: PolygonParams): Mesh {
		const { min, max, mesh } = params;
		const polygon = mesh.clone();

		const offsetByZ = Math.abs(max.z) + Math.abs(min.z);
		polygon.position.z += offsetByZ;

		const point = new Vector3(min.x - FIELD_MARGIN, 0, max.z - FIELD_MARGIN);
		polygon.rotateAround(point, Vector3.Up(), Math.PI / 2);

		return polygon;
	}

	private createPolygonLeftFront(params: PolygonParams): Mesh {
		const { min, max, mesh } = params;
		const polygon = mesh.clone();

		const offsetByX = Math.abs(max.x) + Math.abs(min.x) + 2 * FIELD_MARGIN;
		polygon.position.x += offsetByX;

		const point = new Vector3(max.x + FIELD_MARGIN, 0, max.z - FIELD_MARGIN);
		polygon.rotateAround(point, Vector3.Up(), Math.PI / 2);

		return polygon;
	}

	private createPolygonRightFront(params: PolygonParams): Mesh {
		const { min, max, mesh } = params;
		const polygon = mesh.clone();

		const offsetByZ = Math.abs(max.z) + Math.abs(min.z);
		polygon.position.z -= offsetByZ;

		const point = new Vector3(max.x + FIELD_MARGIN, 0, min.z - FIELD_MARGIN);
		polygon.rotateAround(point, Vector3.Up(), Math.PI / 2);

		return polygon;
	}

	private createMaterial(): StandardMaterial {
		const material = new StandardMaterial('polygonMaterial', this.scene);
		material.emissiveColor = Color3.Black();
		material.disableLighting = true;
		this.resources.push(material);

		return material;
	}
}
