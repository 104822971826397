import { Engine, Scene, Vector3, HavokPlugin, IDisposable } from '@babylonjs/core';
import { MainLight, MainCamera, ErrorMessageManager } from '@golf-ar/core';

import { Field } from './field';
import { GolfBall } from './golf-ball';
import { GolfBallTrajectoryManager } from './golf-ball-trajectory-manager';
import { Golfer } from './golfer';
import { FieldDragManager } from './field-drag-manager';
import { GolfFieldCorners } from './golf-field-corners';
import { BallTrail } from './ball-trail';
import { CollisionManager } from './collision-manager';
import { SceneControlManager } from './scene-control-manager';
import { Ground } from './ground';
import { FieldControlManager } from './field-control-manager';
import { WinAnimation } from './win-animation';
import { GameManager } from './game-manager';
import { LoadingManager } from './loading-manager';

/** Visibility of meshes before the user defines the surface. */
export const VISIBILITY_OF_MESH = 0.5;

/** Visibility of auxiliary meshes. */
export const VISIBILITY_OF_AUXILIARY_MESHES = 0;
const ERROR_MESSAGE = 'Golf AR application is not supported on this mobile device. Please try to run it on other device.';

// Importing this engine via CDN, because npm package have some problems.
// See -https://forum.babylonjs.com/t/unable-to-load-havok-plugin-error-while-loading-wasm-file-from-browser/40289
// This version works OK, but we should keep in mind that we have to keep script with CDN in place.
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const HavokPhysics: () => Promise<unknown>;

/** Base scene. */
export class BaseScene implements IDisposable {

	/** Register for browser/canvas resize events. */
	public readonly onResize: () => void;

	private readonly engine: Engine;

	private readonly scene: Scene;

	private readonly camera: MainCamera;

	private readonly light: MainLight;

	private readonly field: Field;

	private readonly golfBall: GolfBall;

	private readonly golfer: Golfer;

	private readonly ballTrail: BallTrail;

	private readonly golfFieldCorners: GolfFieldCorners;

	private readonly ground: Ground;

	private readonly errorMessageManager: ErrorMessageManager;

	private readonly winAnimationManager: WinAnimation;

	private readonly resources: IDisposable[] = [];

	private readonly loadingManager: LoadingManager;

	/** Game manager. */
	public readonly gameManager: GameManager;

	public constructor(
		private readonly canvas: HTMLCanvasElement,
	) {
		this.engine = new Engine(this.canvas);
		this.scene = new Scene(this.engine);
		this.camera = new MainCamera(this.scene);
		this.light = new MainLight(this.scene);
		this.field = new Field(this.scene);
		this.golfBall = new GolfBall(this.scene);
		this.ballTrail = new BallTrail(this.scene);
		this.golfFieldCorners = new GolfFieldCorners(this.scene);
		this.golfer = new Golfer(this.scene, this.golfBall, this.field);
		this.ground = new Ground(this.scene, this.golfBall, this.field);
		this.gameManager = new GameManager(this.golfBall, this.golfer, this.field);
		this.errorMessageManager = new ErrorMessageManager();
		this.winAnimationManager = new WinAnimation(this.scene);
		this.loadingManager = new LoadingManager();

		this.engine.enableOfflineSupport = false;
		this.engine.runRenderLoop(() => this.scene.render());

		this.scene.blockMaterialDirtyMechanism = true;

		this.onResize = this.engine.resize.bind(this.engine);

		this.createSceneElements();

		this.resources.push(
			this.engine,
			this.scene,
			this.camera,
			this.light,
			this.field,
			this.golfBall,
			this.ballTrail,
			this.golfFieldCorners,
			this.errorMessageManager,
			this.golfer,
			this.loadingManager,
		);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private async createSceneElements(): Promise<void> {
		this.loadingManager.createOverlay();
		const physicsPlugin = await this.addPhysics();
		if (physicsPlugin === null) {
			return;
		}

		this.registerCollisionObserver(physicsPlugin);

		await this.field.create();
		this.golfFieldCorners.create(this.field);
		this.golfBall.create(this.field.startPosition);
		await this.golfer.create(this.golfBall.instanceMesh.absolutePosition);
		await this.winAnimationManager.create(this.field.holePosition);

		this.ground.addChildren([
			this.golfBall.instanceMesh,
			this.golfFieldCorners.instanceMesh,
			this.field.instanceMesh,
			this.golfer.instanceParentGolferMesh,
			this.winAnimationManager.instanceMesh,
		]);

		const trajectoryManager = this.registerManagers();

		this.golfer.createGolferAnimationsEvent(trajectoryManager.startGenerateImpulse.bind(trajectoryManager));

		XR8.XrController.recenter();

		this.loadingManager.dispose();
	}

	private registerManagers(): GolfBallTrajectoryManager {
		const trajectoryManager = new GolfBallTrajectoryManager(
			this.scene,
			this.camera.instance,
			this.golfBall,
			this.ballTrail,
			this.golfer,
			this.winAnimationManager.instanceAnimation,
			this.gameManager.addStroke.bind(this.gameManager),
			this.gameManager.getIsBallInHole.bind(this.gameManager),
		);

		const fieldDragManager = new FieldDragManager(
			this.field,
			this.golfBall,
			this.golfFieldCorners,
			this.ground,
		);

		const fieldControlManager = new FieldControlManager(
			this.camera,
			this.canvas,
			this.ground,
		);

		const sceneControlManager = new SceneControlManager(
			trajectoryManager.ballRest,
			fieldDragManager.setEnabled.bind(fieldDragManager),
			trajectoryManager.setEnabled.bind(trajectoryManager),
			fieldControlManager.setEnabled.bind(fieldControlManager),
		);

		this.registerWinAnimationObserver();

		this.resources.push(fieldControlManager, fieldDragManager, trajectoryManager, sceneControlManager);

		return trajectoryManager;
	}

	private registerCollisionObserver(physicsPlugin: HavokPlugin): void {
		physicsPlugin.onCollisionObservable.add(collisionEvent => {
			const isBallInHole = CollisionManager.isCollisionComplete(this.golfBall.instancePhysicsBody, collisionEvent);
			if (isBallInHole) {
				this.winAnimationManager.startAnimation();
			}
		});
	}

	private registerWinAnimationObserver(): void {
		this.winAnimationManager.instanceAnimation.onAnimationGroupEndObservable.add(() => {
			this.gameManager.isBallInHole$.next(true);
		});
	}

	private createGravityConfiguration(): Vector3 {
		const gravity = -9.81;
		return new Vector3(0, gravity, 0);
	}

	private async addPhysics(): Promise<HavokPlugin | null> {
		try {
			const havokInstance = await HavokPhysics();
			const havokPlugin = new HavokPlugin(true, havokInstance);
			this.scene.enablePhysics(this.createGravityConfiguration(), havokPlugin);

			return havokPlugin;
		} catch (error: unknown) {
			XR8.pause();
			this.errorMessageManager.displayErrorMessage(ERROR_MESSAGE);

			return null;
		}
	}
}
