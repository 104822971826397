import { StandardMaterial, Constants, IDisposable, Scene, Mesh, Vector3, Viewport, VideoTexture, MeshBuilder, ArcRotateCamera, Color3 } from '@babylonjs/core';
import { assertNonNull, LayerMask } from '@golf-ar/core';

/** Video manager. */
export class VideoManager implements IDisposable {

	private videoCamera: ArcRotateCamera;

	private videoTexture: VideoTexture;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
		private readonly viewport: Viewport,
	) {
		this.videoCamera = this.createCamera();
		this.videoTexture = this.createVideo();
	}

	/** Display camera. */
	public displayCamera(): void {
		assertNonNull(this.scene.activeCameras);
		this.scene.activeCameras.push(this.videoCamera);
		this.videoTexture.video.play();
		this.videoTexture.video.addEventListener('ended', () => this.hideCamera(), { once: true });
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private createCamera(): ArcRotateCamera {
		const camera = new ArcRotateCamera('videoCamera', -Math.PI / 2, Math.PI / 2, 15, Vector3.Zero(), this.scene);
		camera.layerMask = LayerMask.VideoAndViewport;
		camera.viewport = this.viewport;
		this.resources.push(camera);

		return camera;
	}

	private hideCamera(): void {
		this.scene.removeCamera(this.videoCamera);
	}

	private createVideo(): VideoTexture {
		const videoPlane = MeshBuilder.CreatePlane('videoPlane', {
			height: 12.1,
			width: 63.85,
			sideOrientation: Mesh.DOUBLESIDE,
		}, this.scene);

		const videoMaterial = new StandardMaterial('videoMaterial', this.scene);
		const videoTexture = new VideoTexture(
			'VideoTexture',
			'./assets/videos/first-stroke.mp4',
			this.scene,
			false,
			false,
			VideoTexture.TRILINEAR_SAMPLINGMODE,
			{
				autoPlay: false,
				autoUpdateTexture: true,
				loop: false,
			},
		);
		videoMaterial.diffuseTexture = videoTexture;
		videoMaterial.depthFunction = Constants.ALWAYS;
		videoMaterial.emissiveColor = new Color3(0.95, 0.95, 0.95);
		videoMaterial.disableLighting = true;
		videoMaterial.disableDepthWrite = true;
		videoPlane.material = videoMaterial;
		videoPlane.layerMask = LayerMask.Video;

		this.resources.push(videoPlane, videoMaterial, videoTexture);

		return videoTexture;
	}
}
