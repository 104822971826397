import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { getGameResultType } from '@golf-ar/core';
import { take } from 'rxjs';

import { SceneService } from '../../../service/scene.service';

import { GameResultDialogContentComponent } from './game-result-dialog-content/game-result-dialog-content.component';

/** Game result dialog data. */
export type GameResultDialogData = {

	/** Number of strokes taken. */
	readonly strokesCount: number;
};

/** Game result dialog component. */
@Component({
	selector: 'gaw-game-result-dialog',
	templateUrl: './game-result-dialog.component.html',
	styleUrls: ['./game-result-dialog.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatDialogModule, GameResultDialogContentComponent, MatIconModule],
})
export class GameResultDialogComponent {
	private readonly dialogRef = inject(MatDialogRef);

	private readonly sceneService = inject(SceneService);

	/** Dialog data. */
	protected readonly dialogData: GameResultDialogData = inject(MAT_DIALOG_DATA);

	/** Game result type. */
	protected readonly gameResultType = getGameResultType(this.dialogData.strokesCount);

	/** Handles start game. */
	protected onStart(): void {
		this.sceneService.resetGame().pipe(
			take(1),
		)
			.subscribe(
				() => this.dialogRef.close(),
			);
	}

	/** Handles share results. */
	protected onShare(): void {
		// TODO Kirillova: Add implementation
		this.dialogRef.close();
	}

	/** Handles open tutorial. */
	protected onOpenTutorial(): void {
		// TODO Kirillova: Add implementation
		this.dialogRef.close();
	}
}
