<div class="container">
	@if (areControlsAvailable()) {
		@if (isSceneControlActive()) {
			<button
				type="button"
				(click)="onToggleSceneControl()"
				class="primary scene-control-button"
			>
				Set & Play
			</button>
			<button
				type="button"
				(click)="onOpenTutorial()"
				class="tertiary tutorial-button"
			>
				<mat-icon svgIcon="help" />
			</button>
		} @else {
			<button
				type="button"
				(click)="onToggleSceneControl()"
				class="tertiary scene-control-button"
			>
				<mat-icon
					class="scene-control-icon"
					svgIcon="ar"
				/>
			</button>
			<button
				type="button"
				(click)="onResetGame()"
				class="tertiary reset-button"
			>
				<mat-icon svgIcon="refresh" />
			</button>
		}
	}
	@if (isLoading()) {
		<gaw-loading-overlay />
	} @else {
		<gaw-tutorial-overlay />
	}
	<gaw-orientation-overlay-message />
	<gaw-scene />
</div>
