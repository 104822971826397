import { IDisposable } from '@babylonjs/core';
import { Button, AdvancedDynamicTexture, Control } from '@babylonjs/gui';
import { assertNonNull } from '@golf-ar/core';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';

/** Button text. */
enum ButtonText {
	Confirm = 'Confirm ground position',
	Change = 'Adjust ground position',
}

/** Scene control manager. */
export class SceneControlManager implements IDisposable {
	private isEnabled = true;

	private readonly button: Button;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly canEnableSceneControl: BehaviorSubject<boolean>,
		private readonly isEnabledFieldDragManager: (value: boolean) => void,
		private readonly isEnabledBallTrajectoryManager: (value: boolean) => void,
		private readonly isEnabledFieldControlManager: (value: boolean) => void,
	) {
		this.button = this.createButton();
		this.button.onPointerUpObservable.add(this.registerPointerUp.bind(this));

		canEnableSceneControl.pipe(
			distinctUntilChanged(),
			tap(canEnable => {
				this.button.isVisible = canEnable;
			}),
		).subscribe();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
		this.canEnableSceneControl.unsubscribe();
	}

	private createButton(): Button {
		const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('ImpactAngleIndicator');
		this.resources.push(advancedTexture);

		const button = Button.CreateSimpleButton('SceneControlButton', ButtonText.Confirm);
		button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
		button.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
		button.left = '10px';
		button.top = '10px';
		button.background = 'white';
		button.width = '75px';
		button.height = '75px';
		button.color = 'black';
		button.fontSize = 14;

		advancedTexture.addControl(button);

		return button;
	}

	private registerPointerUp(): void {
		this.isEnabled = !this.isEnabled;
		this.isEnabledFieldDragManager(this.isEnabled);
		this.isEnabledFieldControlManager(this.isEnabled);
		this.isEnabledBallTrajectoryManager(!this.isEnabled);

		assertNonNull(this.button.textBlock);
		if (this.isEnabled) {
			this.button.textBlock.text = ButtonText.Confirm;
			return;
		}
		this.button.textBlock.text = ButtonText.Change;
	}
}
