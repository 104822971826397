import { AbstractMesh, TransformNode } from '@babylonjs/core';

/**
 * Return the object name, if it's invalid, return the group name.
 * All objects must have a valid name or a group with a valid name.
 * @param object The object to use.
 * @param isValid Function for checking the validity of the name.
 */
export function getValidName<T>(
	object: TransformNode | AbstractMesh,
	isValid: (value: unknown) => value is T,
): T {
	if (isValid(object.name)) {
		return object.name;
	} else if (object.parent && isValid(object.parent.name)) {
		return object.parent.name;
	}

	throw new Error('The object has an invalid group and object name');
}
