import { Scene, ArcRotateCamera, Vector3, Viewport, IDisposable, Mesh, TransformNode, Scalar, Observer } from '@babylonjs/core';
import { assertNonNull, LayerMask } from '@golf-ar/core';

import { Golfer } from './golfer';

const CAMERA_UPPER_BETA_LIMIT = Math.PI / 3;
const CAMERA_LOWER_RADIUS_LIMIT = 2;
const CAMERA_RADIUS_LIMIT = 4;

const LERP_SPEED = 0.01;

/** Camera that follows the ball during shot. */
export class BallFollowCamera implements IDisposable {
	/** Does the camera have to look at the golfer. */
	public shouldSeeGolfer = true;

	private readonly instance: ArcRotateCamera;

	private readonly resources: IDisposable[] = [];

	private updateCameraObservable: Observer<Scene> | null = null;

	public constructor(
		private readonly scene: Scene,
		private readonly target: Vector3,
		private readonly ball: Mesh,
		private readonly viewport: Viewport,
		private readonly golfer: Golfer,
	) {
		this.instance = this.createCamera();
		this.setupCameraBehavior();
	}

	/** Hide camera. */
	public hideCamera(): void {
		this.scene.removeCamera(this.instance);
	}

	/** Display camera. */
	public displayCamera(): void {
		assertNonNull(this.scene.activeCameras);
		this.scene.activeCameras.push(this.instance);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.scene.onBeforeRenderObservable.remove(this.updateCameraObservable);
		this.resources.forEach(resource => resource.dispose());
	}

	private createCamera(): ArcRotateCamera {
		const camera = new ArcRotateCamera('ballFollowCamera', -Math.PI / 2, Math.PI / 3, 5, this.target, this.scene, false);
		camera.viewport = this.viewport;
		camera.inputs.clear();
		this.resources.push(camera);

		return camera;
	}

	private setupCameraBehavior(): void {
		const followCamera = new TransformNode('followCamera', this.scene);
		const followCameraPivot = new TransformNode('followCameraPivot', this.scene);
		this.instance.layerMask = LayerMask.ViewportAndDefault;
		followCameraPivot.parent = followCamera;
		followCamera.parent = this.ball;

		this.instance.useAutoRotationBehavior = true;
		this.instance.upperBetaLimit = CAMERA_UPPER_BETA_LIMIT;

		this.updateCameraObservable = this.scene.onBeforeRenderObservable.add(() => {
			const isCameraActive = this.scene.activeCameras?.some(camera => camera.name === this.instance.name);
			if (isCameraActive) {
				this.updatePosition(followCameraPivot);
				this.updateRotation();
			}
		});
	}

	private updatePosition(followCameraPivot: TransformNode): void {
		const ballPosition = this.ball.getAbsolutePosition().clone();
		this.instance.setTarget(ballPosition);

		// Updating camera position
		const followCameraPivotPosition = followCameraPivot.getAbsolutePosition().clone();
		this.instance.position = Vector3.Lerp(this.instance.position, followCameraPivotPosition, LERP_SPEED);

		if (this.shouldSeeGolfer) {
			this.instance.position = followCameraPivotPosition;
			this.instance.radius = CAMERA_LOWER_RADIUS_LIMIT;
			return;
		}

		// Camera radius restriction
		this.instance.lowerRadiusLimit = CAMERA_LOWER_RADIUS_LIMIT;
		if (this.instance.radius < CAMERA_RADIUS_LIMIT) {
			this.instance.radius = Scalar.Lerp(this.instance.radius, CAMERA_RADIUS_LIMIT, LERP_SPEED);
		}
	}

	private updateRotation(): void {
		if (!this.shouldSeeGolfer) {
			return;
		}

		const ballPosition = this.ball.getAbsolutePosition().clone();
		const golferPosition = this.golfer.instanceMesh.getAbsolutePosition().clone();
		const midpoint = ballPosition.add(golferPosition).scale(0.5);

		// Calculating the direction from the midpoint to the camera
		const direction = this.instance.position.subtract(midpoint);
		direction.normalize();

		const alpha = Math.atan2(direction.z, direction.x);

		// ~65 degrees
		const beta = Math.PI / 2.75;

		this.instance.alpha = alpha;
		this.instance.beta = beta;
	}
}
