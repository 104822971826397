import { Scene, FreeCamera, Vector3, IDisposable } from '@babylonjs/core';

import { assertNonNull } from '../utils';

/** Main camera type. */
export type MainCameraType = FreeCamera;

const MIN_Y_POSITION = 5;
const MAX_Y_POSITION = 150;
const INITIAL_CAMERA_DEFAULTS = {
	position: new Vector3(-25, 40, -1),
	rotation: new Vector3(0.6, 1.5, 0),
} as const;

/** App main camera. */
export class MainCamera implements IDisposable {

	/** Camera instance. */
	public readonly instance: MainCameraType;

	public constructor(private readonly scene: Scene) {
		this.instance = this.initializeCamera();

		// Connect the camera to the XR engine and show camera feed
		// @ts-ignore
		this.instance.addBehavior(XR8.Babylonjs.xrCameraBehavior({ webgl2: true }), true);
	}

	/** Change the camera position based on the front position.*/
	public set cameraPositionByFrontPosition(distance: number) {
		const { x, y, z } = this.instance.getFrontPosition(distance);
		const { rotationQuaternion } = this.instance;
		if (y > MIN_Y_POSITION && y < MAX_Y_POSITION) {
			XR8.XrController.updateCameraProjectionMatrix({
				origin: { x, y, z },
				facing: rotationQuaternion,
			});

			XR8.XrController.recenter();
		}
	}

	/** @inheritdoc */
	public dispose(): void {
		this.instance.dispose();
	}

	private initializeCamera(): FreeCamera {
		const camera = new FreeCamera('freeCam', INITIAL_CAMERA_DEFAULTS.position.clone(), this.scene);
		camera.rotation = INITIAL_CAMERA_DEFAULTS.rotation.clone();
		camera.inputs.addMouseWheel();
		camera.attachControl();
		assertNonNull(this.scene.activeCameras);
		this.scene.activeCameras.push(camera);
		return camera;
	}

	/** Reset the camera position. */
	public resetPosition(): void {
		this.instance.position = INITIAL_CAMERA_DEFAULTS.position.clone();
		this.instance.rotationQuaternion = INITIAL_CAMERA_DEFAULTS.rotation.clone().toQuaternion();
		this.cameraPositionByFrontPosition = 0;
	}
}
