import { Color3, DirectionalLight, IDisposable, Scene, Vector3 } from '@babylonjs/core';

/** Main lights of the app. */
export class MainLight implements IDisposable {
	private readonly mailLight: DirectionalLight;

	public constructor(
		private readonly scene: Scene,
	) {
		this.mailLight = this.createDirectionalLight();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.mailLight.dispose();
	}

	private createDirectionalLight(): DirectionalLight {
		const directionalLight = new DirectionalLight('mainLight', new Vector3(25, -25, 15), this.scene);
		directionalLight.intensity = 1.25;
		directionalLight.specular = Color3.Blue();

		return directionalLight;
	}
}
