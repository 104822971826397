import { AbstractMesh, AnimationGroup } from '@babylonjs/core';

/** Type of golfer. */
export enum GolferType {

	// Driver and iron have one common model
	DriverAndIron = 'DriverAndIron',
	Putter = 'Putter',
}

/** Type of golf club. */
export enum GolfClubType {
	Driver = 'Driver',
	Iron = 'Iron',
	Putter = 'Putter',
}

/** Golfer animation. */
export type GolferAnimation = {

	/** Golfer driver and iron animation. */
	// Driver and iron have one common animation
	readonly driverAndIron: AnimationGroup;

	/** Golfer putt animation. */
	readonly putt: AnimationGroup;
};

/** Golfer. */
export type GolferMesh = {

	/** Driver and iron. */
	// Driver and iron have one common model
	readonly driverAndIron: AbstractMesh[];

	/** Putter. */
	readonly putter: AbstractMesh[];
};

/** Types of golf club in the driver and iron models. */
export type DriverAndIronGolfClub = {

	/** Driver. */
	readonly driver: AbstractMesh;

	/** Iron. */
	readonly iron: AbstractMesh;
};
