/**
 * Asserts value is non-nullable.
 * @param value Value to assert.
 * @param message Error message to show.
 */
export function assertNonNull<T>(value: T | null, message = 'Value is null'): asserts value is NonNullable<T> {
	if (value == null) {
		throw new Error(message);
	}
}
