import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { first, tap } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';

import { SceneService } from '../../service/scene.service';
import { TutorialStorageService } from '../../service/tutorial-storage.service';

import { SceneComponent } from './scene/scene.component';
import { GameResultDialogComponent, GameResultDialogData } from './game-result-dialog/game-result-dialog.component';
import { WelcomingDialogComponent } from './welcoming-dialog/welcoming-dialog.component';
import { OrientationOverlayMessageComponent } from './orientation-overlay-message/orientation-overlay-message.component';
import { TutorialOverlayComponent } from './tutorial-overlay/tutorial-overlay.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

const GAME_RESULT_DIALOG_CONFIG: MatDialogConfig = {
	width: '95vw',
	height: '50vh',
	maxWidth: '450px',
	minHeight: '420px',
	disableClose: true,
};

const WELCOMING_DIALOG_CONFIG: MatDialogConfig = {
	width: '100vw',
	height: '100vh',
	maxWidth: '100vw',
};

/** Game component. */
@Component({
	selector: 'gaw-game',
	templateUrl: './game.component.html',
	styleUrls: ['./game.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SceneComponent,
		OrientationOverlayMessageComponent,
		LoadingOverlayComponent,
		TutorialOverlayComponent,
		MatIcon,
	],
})
export class GameComponent implements OnInit {
	private readonly dialog = inject(MatDialog);

	private readonly sceneService = inject(SceneService);

	private readonly tutorialStorage = inject(TutorialStorageService);

	private readonly destroyRef = inject(DestroyRef);

	/** Whether scene control button is active. */
	protected readonly isSceneControlActive = toSignal(this.sceneService.isSceneControlActive$);

	/** Whether scene is loading or not. */
	protected readonly isLoading = toSignal(this.sceneService.isLoading$);

	/** Whether controls are available or not. */
	protected readonly areControlsAvailable = computed(() =>
		this.canToggleSceneControl() &&
    this.isTutorialShown() &&
    !this.isLoading());

	private readonly isTutorialShown = this.tutorialStorage.isShown;

	private readonly canToggleSceneControl = toSignal(this.sceneService.canToggleSceneControl$);

	/** @inheritdoc */
	public constructor() {
		this.openWelcomingDialog();
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		this.sceneService.endGameStrokes$.pipe(
			tap(strokesCount => this.openGameResultDialog(strokesCount)),
			takeUntilDestroyed(this.destroyRef),
		).subscribe();
	}

	/** Handles toggle scene control button click. */
	protected onToggleSceneControl(): void {
		this.sceneService.toggleSceneControl()
			.pipe(first())
			.subscribe();
	}

	/** Handles reset game button click. */
	protected onResetGame(): void {
		this.sceneService.resetGame()
			.pipe(first())
			.subscribe();
	}

	/** Handles open tutorial button click. */
	protected onOpenTutorial(): void {
		this.tutorialStorage.removeIsShown()
			.pipe(first())
			.subscribe();
	}

	private openWelcomingDialog(): void {
		this.dialog.open(WelcomingDialogComponent, WELCOMING_DIALOG_CONFIG);
	}

	private openGameResultDialog(strokesCount: number): void {
		this.dialog.open<GameResultDialogComponent, GameResultDialogData>(GameResultDialogComponent, {
			...GAME_RESULT_DIALOG_CONFIG,
			data: { strokesCount },
		});
	}
}
