import { IDisposable, PointerDragBehavior, Vector3 } from '@babylonjs/core';

import { GolfBall } from './golf-ball';
import { Field } from './field';
import { GolfFieldCorners } from './golf-field-corners';
import { VISIBILITY_OF_MESH } from './scene';
import { Ground } from './ground';

/** Field drag manager. */
export class FieldDragManager implements IDisposable {

	private readonly pointerDragBehavior: PointerDragBehavior;

	public constructor(
		private readonly field: Field,
		private readonly golfBall: GolfBall,
		private readonly corners: GolfFieldCorners,
		private readonly ground: Ground,
	) {
		this.pointerDragBehavior = new PointerDragBehavior({ dragPlaneNormal: new Vector3(0, 1, 0) });
		this.pointerDragBehavior.moveAttached = false;

		this.pointerDragBehavior.onDragObservable.add(event => {
			this.dragMeshes(event.delta.clone());
		});

		this.enableDraggable();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.pointerDragBehavior.detach();
	}

	/**
	 * Sets a boolean indicating whether field drag is enabled.
	 * @param value Defines the new enabled state.
	 */
	public setEnabled(value: boolean): void {
		if (value) {
			this.enableDraggable();
			return;
		}
		this.disableDraggable();
	}

	private enableDraggable(): void {
		this.ground.shouldDisablePhysicsBodyStep = false;
		this.corners.setCornersVisibility(VISIBILITY_OF_MESH);
		this.field.setMeshVisibility(VISIBILITY_OF_MESH);

		this.pointerDragBehavior.attach(this.ground.instance);
	}

	private disableDraggable(): void {
		this.pointerDragBehavior.detach();

		this.ground.shouldDisablePhysicsBodyStep = true;

		const fieldVisibility = 1;
		const cornersVisibility = 0;
		this.field.setMeshVisibility(fieldVisibility);
		this.corners.setCornersVisibility(cornersVisibility);
	}

	private dragMeshes(delta: Vector3): void {
		document.addEventListener('touchmove', eventTouch => {
			const groundMesh = this.ground.instance;
			if (eventTouch.targetTouches.length === 1) {
				const ballPosition = this.golfBall.instanceMesh.absolutePosition.addInPlace(delta);
				this.golfBall.ballPositionBeforeImpact = ballPosition;
				groundMesh.setAbsolutePosition(groundMesh.absolutePosition.addInPlace(delta));
			}
		}, { once: true });
	}
}
