import { IPhysicsCollisionEvent, PhysicsBody, Vector3 } from '@babylonjs/core';
import { FieldMeshName, getValidName } from '@golf-ar/core';

type CollisionSurfaceMesh = FieldMeshName.SandBunker |
FieldMeshName.Field |
FieldMeshName.Green |
FieldMeshName.Tee |
FieldMeshName.Fireway |
FieldMeshName.Hole;

type MapSurfaceToPhysicsPropertiesType = Readonly<Record<CollisionSurfaceMesh, {

	/** Inertia. */
	readonly inertia: number;

	/** Damping. */
	readonly damping: number;
}>>;

// The reason why we can't use the friction itself, see here https://forum.babylonjs.com/t/friction-not-working/490
const MAP_SURFACE_TO_PHYSICS_PROPERTIES: MapSurfaceToPhysicsPropertiesType = {
	[FieldMeshName.SandBunker]: {
		damping: 1,
		inertia: 1,
	},
	[FieldMeshName.Field]: {
		damping: 0.75,
		inertia: 0.02,
	},
	[FieldMeshName.Green]: {
		damping: 0.5,
		inertia: 0.005,
	},
	[FieldMeshName.Tee]: {
		damping: 0.5,
		inertia: 0.005,
	},
	[FieldMeshName.Fireway]: {
		damping: 0.65,
		inertia: 0.01,
	},
	[FieldMeshName.Hole]: {
		damping: 1,
		inertia: 1,
	},
};

/** Collision manager. */
export class CollisionManager {

	private static ballLastCollideAgainst: FieldMeshName | null = null;

	/**
	 * Registers all collisions of the ball with the surface and
	 * change physics properties of the ball depending on the type of surface.
	 * @param ballPhysicsBody Physics body of a golf ball.
	 * @param collisionEvent Observable for collision started and collision continued events.
	 * @returns Whether the collision is complete and the ball has collided with the hole.
	 */
	public static isCollisionComplete(
		ballPhysicsBody: PhysicsBody,
		collisionEvent: IPhysicsCollisionEvent,
	): boolean {

		// Check if the physics body of the ball is collides with the surface.
		if (ballPhysicsBody !== collisionEvent.collider) {
			return false;
		}

		const name = getValidName(collisionEvent.collidedAgainst.transformNode, FieldMeshName.isValid);

		// Check for name is needed in order to avoid multiple changes physics properties
		if (this.ballLastCollideAgainst === name) {
			return false;
		}

		/** Y at which the golf ball is considered to have rolled into the hole. */
		const holeYPosition = 0;

		// Check if the ball has hit the hole.
		if (
			name === FieldMeshName.Hole &&
			ballPhysicsBody.transformNode.absolutePosition.y <= holeYPosition
		) {

			// Physics properties are necessary for the ball to stop completely,
			// because at the moment of stopping there may be a collision with the hole and impulse transfer
			this.changeBallPhysicsProperties(ballPhysicsBody, name);
			ballPhysicsBody.setLinearVelocity(Vector3.Zero());
			ballPhysicsBody.setAngularVelocity(Vector3.Zero());
			this.ballLastCollideAgainst = name;

			return true;
		}

		// The ball may bump the hole but not roll,
		// so check your position to make sure the ball has rolled into the hole
		if (this.isKeyMapSurfaceToPhysicsProperties(name) && name !== FieldMeshName.Hole) {
			this.changeBallPhysicsProperties(ballPhysicsBody, name);
			this.ballLastCollideAgainst = name;
			return false;
		}

		return false;
	}

	private static isKeyMapSurfaceToPhysicsProperties(name: FieldMeshName): name is CollisionSurfaceMesh {
		return [...Object.keys(MAP_SURFACE_TO_PHYSICS_PROPERTIES)].includes(name);
	}

	private static changeBallPhysicsProperties(ballPhysicsBody: PhysicsBody, name: CollisionSurfaceMesh): void {
		const { damping, inertia } = MAP_SURFACE_TO_PHYSICS_PROPERTIES[name];
		ballPhysicsBody.setLinearDamping(damping);
		ballPhysicsBody.setAngularDamping(damping);
		ballPhysicsBody.setMassProperties({ inertia: new Vector3(inertia, inertia, inertia) });
	}
}
