import { AbstractMesh, AnimationGroup, IDisposable, Scene, SceneLoader, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import { assertNonNull, getRootMesh } from '@golf-ar/core';

/** Win animation. */
export class WinAnimation implements IDisposable {

	private _instanceAnimation: AnimationGroup | null = null;

	private _instanceMeshes: AbstractMesh[] | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(private readonly scene: Scene) { }

	/**
	 * Creates win animation.
	 * @param position Position mesh.
	 */
	public async create(position: Vector3): Promise<void> {
		const { animation, meshes } = await this.createAnimation(position);
		this._instanceAnimation = animation;
		this._instanceMeshes = meshes;

		this.instanceAnimation.onAnimationGroupEndObservable.add(() => {
			this.isVisible = false;
		});
	}

	/** Instance animation. */
	public get instanceAnimation(): AnimationGroup {
		assertNonNull(this._instanceAnimation);
		return this._instanceAnimation;
	}

	/** Instance mesh. */
	public get instanceMesh(): AbstractMesh {
		return getRootMesh(this.instanceMeshes);
	}

	/** Start the win animation. */
	public startAnimation(): void {
		if (this.instanceMesh.isVisible) {
			return;
		}

		this.isVisible = true;
		this.instanceAnimation.play(false);
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private get instanceMeshes(): AbstractMesh[] {
		assertNonNull(this._instanceMeshes);
		return this._instanceMeshes;
	}

	private async createAnimation(position: Vector3): Promise<{
		animation: AnimationGroup;
		meshes: AbstractMesh[];
	}> {
		const { animationGroups, meshes } = await SceneLoader.ImportMeshAsync(
			'', './assets/animation/', 'win-animation.glb', this.scene,
		);
		const animation = animationGroups.at(0);
		assertNonNull(animation);
		animation.stop();
		meshes.forEach(mesh => {
			mesh.isVisible = false;
			mesh.isPickable = false;
		});

		const mesh = getRootMesh(meshes);
		mesh.setAbsolutePosition(position);

		this.resources.push(...animationGroups, ...meshes);

		return { animation, meshes };
	}

	private set isVisible(value: boolean) {
		this.instanceMeshes.forEach(mesh => {
			mesh.isVisible = value;
		});
	}
}
