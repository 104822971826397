import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReadonlyRecord, GameResultType } from '@golf-ar/core';

type GameResultContent = {

	/** Title text. */
	readonly titleText: string;

	/** Name icon. */
	readonly nameIcon: string;

	/** Message. */
	readonly message: string;
};

const RESULT_TYPE_TO_RESULT_CONTENT: ReadonlyRecord<GameResultType, GameResultContent> = {
	great: { titleText: 'GREAT!', nameIcon: 'great-result', message: 'Wow! Great!' },
	nice: { titleText: 'NICE', nameIcon: 'nice-result', message: '' },
	par: { titleText: 'PAR', nameIcon: 'par-result', message: '' },
	miss: { titleText: 'MISS', nameIcon: 'miss-result', message: 'Try again' },
};

/** Game result content dialog component. */
@Component({
	selector: 'gaw-game-result-dialog-content',
	templateUrl: './game-result-dialog-content.component.html',
	styleUrls: ['./game-result-dialog-content.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameResultDialogContentComponent {

	/** Game result type. */
	@Input({ required: true })
	public type: GameResultType = 'great';

	/** Count of strokes. */
	@Input({ required: true })
	public strokesCount = 0;

	/** Gets game result content. */
	protected getResultContent(): GameResultContent {
		return RESULT_TYPE_TO_RESULT_CONTENT[this.type];
	}

	/** Gets icon URL. */
	protected getIconUrl(): string {
		return `assets/icons/${this.getResultContent().nameIcon}.svg`;
	}

	/** Gets text message based on result type and strokes count. */
	protected get resultMessage(): string {
		const message = `${this.strokesCount} Stroke${this.strokesCount > 1 ? 's' : ''}`;
		if (this.type === 'great') {
			return `${RESULT_TYPE_TO_RESULT_CONTENT[this.type].message} ${message}`;
		}
		if (this.type === 'miss') {
			return RESULT_TYPE_TO_RESULT_CONTENT[this.type].message;
		}

		return message;
	}
}
