import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

/** Welcoming dialog component. */
@Component({
	selector: 'gaw-welcoming-dialog',
	templateUrl: './welcoming-dialog.component.html',
	styleUrls: ['./welcoming-dialog.component.css'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MatDialogModule, MatIconModule],
})
export class WelcomingDialogComponent {
	private readonly dialogRef = inject(MatDialogRef);

	/** Handles open tutorial. */
	protected onOpenTutorial(): void {
		// TODO Kirillova: Add implementation
		this.dialogRef.close();
	}

	/** Handles start game. */
	protected onStart(): void {
		this.dialogRef.close();
	}
}
