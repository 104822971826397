import { BehaviorSubject, filter, map, Observable, withLatestFrom } from 'rxjs';
import { getGameResultType } from '@golf-ar/core';

import { GolfBall } from './golf-ball';
import { Golfer } from './golfer';
import { Field } from './field';

const GAME_MANAGER_DEFAULTS = {
	strokes: 0,
	isBallInHole: false,
};

/** Game manager. */
export class GameManager {

	/** Status of a ball in a hole. */
	public readonly isBallInHole$ = new BehaviorSubject(GAME_MANAGER_DEFAULTS.isBallInHole);

	/** End game strokes. */
	public readonly endGameStrokes$: Observable<number>;

	/** Strokes count. */
	private readonly strokesCount$ = new BehaviorSubject(GAME_MANAGER_DEFAULTS.strokes);

	public constructor(
		private readonly golfBall: GolfBall,
		private readonly golfer: Golfer,
		private readonly field: Field,
	) {
		this.endGameStrokes$ = this.strokesCount$.pipe(
			withLatestFrom(this.isBallInHole$),
			filter(([strokesCount, isBallInHole]) => getGameResultType(strokesCount) === 'miss' || isBallInHole),
			map(([strokesCount]) => strokesCount),
		);
	}

	/** Resets the game. */
	public resetGame(): void {
		this.golfBall.setPosition(this.field.startPosition, true);
		this.golfer.changeGolferPosition();
		this.golfer.instanceAnimation.reset();
		this.isBallInHole$.next(GAME_MANAGER_DEFAULTS.isBallInHole);
		this.strokesCount$.next(GAME_MANAGER_DEFAULTS.strokes);
	}

	/** Adds stroke. */
	public addStroke(): void {
		this.strokesCount$.next(this.strokesCount$.value + 1);
	}

	/** Gets ball is in the hole status. */
	public getIsBallInHole(): boolean {
		return this.isBallInHole$.value;
	}
}
