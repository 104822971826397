import { ChangeDetectionStrategy, Component, inject, signal, computed, effect } from '@angular/core';
import { LottieComponent } from 'ngx-lottie';
import { toSignal } from '@angular/core/rxjs-interop';
import { first } from 'rxjs';

import { LottieService } from '../../../service/lottie.service';
import { TutorialStorageService } from '../../../service/tutorial-storage.service';

/** Tutorial overlay message component. */
@Component({
	selector: 'gaw-tutorial-overlay',
	templateUrl: './tutorial-overlay.component.html',
	styleUrls: ['./tutorial-overlay.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LottieComponent],
})
export class TutorialOverlayComponent {
	private readonly lottieService = inject(LottieService);

	private readonly tutorialStorage = inject(TutorialStorageService);

	/** Whether animation is visible. */
	protected readonly isVisible = signal(true);

	/** Animations. */
	protected readonly animations = toSignal(this.lottieService.animations$);

	private readonly isShown = this.tutorialStorage.isShown;

	private readonly currentAnimationIndex = signal(0);

	/** Current animation. */
	protected readonly currentAnimation = computed(() => {
		const animations = this.animations();
		const index = this.currentAnimationIndex();
		return animations?.[index] ?? null;
	});

	/** @inheritdoc */
	public constructor() {
		effect(() => {
			this.isVisible.set(!this.isShown());
			if (!this.isShown()) {
				this.currentAnimationIndex.set(0);
			}
		});
	}

	/** Switch animation. */
	protected switchAnimation(): void {
		const animations = this.animations();
		const currentIndex = this.currentAnimationIndex();
		if (!animations) {
			return;
		}

		if (currentIndex >= animations.length - 1) {
			this.tutorialStorage.saveIsShown(true)
				.pipe(first())
				.subscribe();
		} else {
			this.currentAnimationIndex.set(currentIndex + 1);
		}
	}
}
