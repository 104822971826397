<div class="dialog">
	<ng-container mat-dialog-content>
		<div class="dialog__content">
			<img
				class="logo"
				src="assets/icons/logo.svg"
			/>
		</div>
	</ng-container>
	<ng-container mat-dialog-actions>
		<div class="dialog__actions">
			<!-- TODO (Sherstkov) Delete 'hide' class when implementation is ready -->
			<button
				type="button"
				(click)="onOpenTutorial()"
				class="primary button-tutorial visually-hidden"
			>
				How to Play?
			</button>
			<button
				type="button"
				(click)="onStart()"
				class="secondary button-play"
			>
				<mat-icon svgIcon="ar" />
				Start game
			</button>
		</div>
	</ng-container>
</div>
