import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler } from '@angular/core';

import { ErrorNotifier } from '../error-logger';

/** App config. */
export const appConfig: ApplicationConfig = {
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: ErrorHandler,
			useClass: ErrorNotifier,
		},
	],
};
