import { TrailMesh, StandardMaterial, Color3, IDisposable, Scene, Mesh, Vector3 } from '@babylonjs/core';
import { assertNonNull } from '@golf-ar/core';

import { GOLF_BALL_RADIUS } from './golf-ball';

const BALL_TRAIL_NAME = 'BallTrail';

/** The trail of the ball that appears when moving. */
export class BallTrail implements IDisposable {

	private instance: TrailMesh | null = null;

	private readonly resources: IDisposable[] = [];

	public constructor(
		private readonly scene: Scene,
	) {}

	/** Golf ball mesh. */
	public get instanceMesh(): TrailMesh {
		assertNonNull(this.instance);
		return this.instance;
	}

	/**
	 * Creates trail mesh.
	 * @param ball Golf ball.
	 */
	public create(ball: Mesh): void {
		if (this.scene.getMeshByName(BALL_TRAIL_NAME) === null) {
			this.instance = this.createMesh(ball);
		}
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private createMesh(ball: Mesh): TrailMesh {
		const trail = new TrailMesh(BALL_TRAIL_NAME, ball, this.scene, GOLF_BALL_RADIUS, 25);
		ball.rotate(Vector3.Right(), Math.PI / 2);
		trail.visibility = 0.75;
		this.setMaterialOnTrailOfBall(trail);
		this.resources.push(trail);

		return trail;
	}

	private setMaterialOnTrailOfBall(trail: TrailMesh): void {
		const material = new StandardMaterial('BallTrailMaterial', this.scene);
		material.diffuseColor = Color3.White();
		material.emissiveColor = Color3.White();
		trail.material = material;

		this.resources.push(material);
	}
}
