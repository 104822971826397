import { Viewport, IDisposable, Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture, Rectangle } from '@babylonjs/gui';
import { assertNonNull, LayerMask } from '@golf-ar/core';

const DEFAULT_VIEWPORT_WIDTH = 0.94;
const DEFAULT_VIEWPORT_HEIGHT = 0.25;

/** Video and ball-following camera viewport. */
export class CameraViewport implements IDisposable {
	/** Viewport. */
	public readonly instance: Viewport;

	private readonly resources: IDisposable[] = [];

	public constructor(private readonly scene: Scene) {
		this.instance = this.createViewport();
		this.createViewportBackground();
		this.createViewportBorder();
	}

	/** @inheritdoc */
	public dispose(): void {
		this.resources.forEach(resource => resource.dispose());
	}

	private createViewport(): Viewport {
		const viewportWidth = this.calculateViewportWidth();
		const viewportX = (1 - viewportWidth) / 2;
		const viewportY = 0.72;

		return new Viewport(viewportX, viewportY, viewportWidth, DEFAULT_VIEWPORT_HEIGHT);
	}

	private createViewportBackground(): void {
		const backgroundTexture = AdvancedDynamicTexture.CreateFullscreenUI('backgroundTexture', false);
		assertNonNull(backgroundTexture.layer);
		backgroundTexture.layer.layerMask = LayerMask.Viewport;
		const background = new Rectangle('viewportBackground');
		background.background = '#FFFFFF';

		backgroundTexture.addControl(background);
		this.resources.push(backgroundTexture);
	}

	private createViewportBorder(): void {
		const borderTextureHeight = 120;
		const { width, height } = this.instance;
		const borderTextureWidth = borderTextureHeight * this.getAspectRatio(width, height);
		const borderTexture = AdvancedDynamicTexture.CreateFullscreenUI('borderTexture', true, {
			width: borderTextureWidth,
			height: borderTextureHeight,
		});
		assertNonNull(borderTexture.layer);
		borderTexture.layer.layerMask = LayerMask.Viewport;
		const border = new Rectangle('viewportBorder');
		border.thickness = 3;
		border.color = '#000000';

		borderTexture.addControl(border);
		this.resources.push(borderTexture);
	}

	private getAspectRatio(viewportWidth: number, viewportHeight: number): number {
		const renderWidth = this.scene.getEngine().getRenderWidth();
		const renderHeight = this.scene.getEngine().getRenderHeight();

		const viewportAspectRatio = (renderWidth * viewportWidth) / (renderHeight * viewportHeight);
		return viewportAspectRatio;
	}

	private calculateViewportWidth(): number {
		const maxViewportAspectRatio = 5;
		if (this.getAspectRatio(DEFAULT_VIEWPORT_WIDTH, DEFAULT_VIEWPORT_HEIGHT) < maxViewportAspectRatio) {
			return DEFAULT_VIEWPORT_WIDTH;
		}
		const renderWidth = this.scene.getEngine().getRenderWidth();
		const renderHeight = this.scene.getEngine().getRenderHeight();

		const width = maxViewportAspectRatio * DEFAULT_VIEWPORT_HEIGHT * renderHeight / renderWidth;
		return width;
	}
}
