import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { StorageService } from './storage.service';

const TUTORIAL_IS_SHOWN_KEY = 'isTutorialShown';

/** Tutorial storage. */
@Injectable({ providedIn: 'root' })
export class TutorialStorageService {

	private readonly storageService = inject(StorageService);

	/** Has tutorial shown for current user. */
	public readonly isShown = toSignal(
		this.storageService.get<boolean | null>(TUTORIAL_IS_SHOWN_KEY).pipe(
			map(Boolean),
		),
	);

	/**
	 * Saves isShown.
	 * @param isTutorialShown IsShown to save.
	 */
	public saveIsShown(
		isTutorialShown: boolean,
	): Observable<void> {
		return this.storageService.save(TUTORIAL_IS_SHOWN_KEY, isTutorialShown);
	}

	/** Removes current isShown. */
	public removeIsShown(): Observable<void> {
		return this.storageService.remove(TUTORIAL_IS_SHOWN_KEY);
	}
}
