import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AnimationItem, AnimationOptions } from 'ngx-lottie/lib/symbols';
import { forkJoin, map, Observable, shareReplay } from 'rxjs';

import { LoadedAnimation, PendingAnimation } from '../utils/types/animation';

/** Service to handle lottie animations. */
@Injectable({
	providedIn: 'root',
})
export class LottieService {
	private readonly http = inject(HttpClient);

	private readonly animations: readonly PendingAnimation[] = [
		{
			path: '/assets/tutorial/move.json',
			message: 'Move the field using touch screen.',
			title: 'Positioning Basics',
		},
		{
			path: '/assets/tutorial/rotate.json',
			message: 'Rotate the field by dragging it with two fingers.',
			title: 'Positioning Basics',
		},
		{
			path: '/assets/tutorial/scale.json',
			message: 'Scale the field by pinching it.',
			title: 'Positioning Basics',
		},
		{
			path: '/assets/tutorial/hit.json',
			message: 'Drag the ball to set the power, then release at the perfect angle to strike.',
			title: 'Make the Shot',
		},
		{
			path: '/assets/tutorial/hit10degrees.json',
			message: 'A low and fast shot. Ideal for precise targeting.',
			title: 'Make the Shot',
		},
		{
			path: '/assets/tutorial/hit40degrees.json',
			message: 'A high and soaring shot. Perfect for overcoming obstacles.',
			title: 'Make the Shot',
		},
	];

	private readonly defaultOptions: Partial<AnimationOptions> = {
		renderer: 'svg',
		loop: true,
		autoplay: true,
	};

	/** Preloaded animation with messages. */
	public readonly animations$ = this.preloadAnimations().pipe(
		shareReplay({ refCount: true, bufferSize: 1 }),
	);

	private preloadAnimations(): Observable<LoadedAnimation[]> {
		const requests = this.animations.map(({ path, message, title }) =>
			this.http.get<AnimationItem>(path).pipe(
				map(animationItem => ({
					options: {
						...this.defaultOptions,
						animationData: animationItem,
					} as AnimationOptions,
					message,
					title,
				})),
			));

		return forkJoin(requests);
	}
}
