import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { APP_CONFIG } from './app-config';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (APP_CONFIG.isProduction) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(error => console.error(error));
