import { Vector2 } from '@babylonjs/core';

/**
 * Calculate distance between 2D points.
 * @param firstPoint First point.
 * @param secondPoint SecondPoint.
 * @param isAbsolute Whether distance calculated as absolute number.
 */
export function calculateDistanceBetween2DPoints(
	firstPoint: Vector2,
	secondPoint: Vector2,
	isAbsolute = true,
): number {
	const xDistance = firstPoint.x - secondPoint.x;
	const yDistance = firstPoint.y - secondPoint.y;
	return Math.sqrt(
		Math.pow(isAbsolute ? Math.abs(xDistance) : xDistance, 2) +
		Math.pow(isAbsolute ? Math.abs(yDistance) : yDistance, 2),
	);
}
