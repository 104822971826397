<div
	class="overlay orientation-overlay"
	#orientationOverlay
>
	<div class="message">
		<img
			class="message__image"
			src="assets/icons/rotate-phone.svg"
		/>
		<p>Rotate the device for a better experience</p>
	</div>
</div>
