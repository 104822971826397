/** Layer mask. */
export enum LayerMask {

	/** Viewport and default layer mask. */
	ViewportAndDefault = 0x1FFFFFFF,

	/** Viewport. */
	Viewport = 0x10000000,

	/** Video. */
	Video = 0x20000000,

	/** Video and viewport. */
	VideoAndViewport = 0x30000000,
}
